import Player from './Player';
import {useEffect, useRef} from 'react';

interface Props {
  src: string;
  width?: string;
  height?: string;
  style?: Record<string, string>;
  play?: boolean;
  loop?: boolean;
}

const LottiePlayer = ({src, width, height, style, play, loop}: Props) => {
  const playerRef = useRef<any>(null);

  const playerStyle = {
    width: width ?? '100%',
    height: height ?? '100%',
    ...style,
  };

  useEffect(() => {
    if (play && playerRef.current != null) {
      playerRef.current?.play();
    }
  }, [play, playerRef]);

  return (
    <Player
      src={src}
      style={playerStyle}
      keepLastFrame={true}
      autoplay={false}
      loop={loop ?? false}
      lottieRef={ref => {
        playerRef.current = ref;
      }}
    />
  );
};

export default LottiePlayer;
